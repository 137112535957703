<template>
  <div
    @click="playPauseVideo"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    class="cursor-pointer"
  >
    <video-background
      v-if="currentVideo"
      ref="videobackground"
      :loop="!isMaximized"
      id="videoBg"
      :src="currentVideo"
      style="max-height: 736px; height: 100vh; max-width: 414px;"
      :muted="isMuted"
      @ended="videoEnds"
      playsinline
      webkit-playsinline
      @playing="videoPlaying"
      :overlay="isVideoOverlay ? 'rgba(0, 0, 0, 0.3)' : ''"
    >
      <b-row class="mt-1 ">
        <b-col
          class="voice-controller d-flex justify-content-between"
          :class="isMaximized ? 'px-3' : 'px-2'"
        >
          <feather-icon
            @click.stop="setVoice"
            size="21"
            icon="Volume2Icon"
            v-if="!isMuted"
          />
          <feather-icon
            size="21"
            icon="VolumeXIcon"
            v-if="isMuted"
            @click.stop="setVoice"
          />
          <feather-icon
            size="21"
            icon="RepeatIcon"
            v-if="isVideoEnded"
            @click.stop="videoRepeat"
          />
          <feather-icon
            size="21"
            icon="Minimize2Icon"
            v-if="isMaximized"
            @click.stop="minimize"
          />
          <feather-icon size="21" icon="XIcon" v-else @click.stop="close" />
        </b-col>
      </b-row>
      <template v-if="isMaximized">
        <b-row class="video-custom-button" v-if="!answersSentSuccess">
          <b-col>
            <div
              v-if="getCurrentSource.interactionType === 'socials'"
              class="socials-wrapper"
            >
              <img
                v-if="getSocialsList.isWhatsapp"
                @click.stop="openSocial(getSocialsList.whatsapp)"
                :src="require('../../../assets/images/socials/whatsapp.png')"
                alt="whatsapp"
                width="72"
              />
              <img
                v-if="getSocialsList.isTelegram"
                @click.stop="openSocial(getSocialsList.telegram)"
                :src="require('../../../assets/images/socials/telegram.png')"
                alt="telegram"
                width="72"
              />
              <img
                v-if="getSocialsList.isViber"
                @click.stop="openSocial(getSocialsList.viber)"
                :src="require('../../../assets/images/socials/viber.png')"
                alt="viber"
                width="72"
              />
              <img
                v-if="getSocialsList.isInstagram"
                @click.stop="openSocial(getSocialsList.instagram)"
                :src="require('../../../assets/images/socials/instagram.png')"
                alt="instagram"
                width="72"
              />
              <img
                v-if="getSocialsList.isVk"
                @click.stop="openSocial(getSocialsList.vk)"
                :src="require('../../../assets/images/socials/vk.png')"
                alt="vk"
                width="72"
              />
            </div>
            <div v-else-if="isForm" class="px-2">
              <h4 style="color: white;">
                {{
                  getCurrentForm.title
                    ? getCurrentForm.title
                    : getCurrentForm.name
                }}
              </h4>
              <p class="card-text">
                {{ getCurrentForm.description }}
              </p>
              <validation-observer ref="leadFormsRules">
                <b-row
                  v-for="question in getCurrentForm.questions"
                  :key="question.id"
                  class="form-elements"
                >
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      :name="question.title"
                      :vid="question.id"
                      :rules="getValidationType(question.type)"
                      mode="passive"
                    >
                      <b-form-input
                        v-model="leadFormsAnswers.answers[question.id]"
                        :placeholder="question.title"
                        size="lg"
                        @click.stop
                        :state="errors[0] ? false : null"
                      >
                      </b-form-input>
                    </validation-provider>
                  </b-col>
                </b-row>
              </validation-observer>
              <b-row class="form-elements-button">
                <b-col class="w-100">
                  <b-button
                    @click.stop="saveFormAnswers"
                    class="w-100"
                    variant="primary"
                    size="lg"
                    :style="'background-color:' + sendFormColor + ' !important'"
                  >
                    Отправить
                  </b-button>
                  <b-button
                    variant="flat-danger"
                    squared
                    @click.stop="closeForm"
                  >
                    <feather-icon icon="XIcon" size="21" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <b-col vertical v-else>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-for="button in currentVideoControls"
                :key="button.id"
                size="lg"
                class="w-100 custom-button"
                :style="
                  `background-color: ${
                    button.background ? button.background.hex : '#7367f0'
                  } !important`
                "
                @click.stop="handleAction(button)"
              >
                <span>
                  {{ button.text }}
                </span>
              </b-button>
            </b-col>
          </b-col>
        </b-row>
        <div v-else class="answer-success-overlay px-1">
          <div>
            <feather-icon size="58" icon="CheckCircleIcon" />
            <div>
              {{
                getCurrentForm.sucсessText
                  ? getCurrentForm.sucсessText
                  : "Спасибо, Ваша заявка принята"
              }}
            </div>
          </div>
        </div>
      </template>
      <div class="quest-footer" v-if="isMaximized">
        <span>
          Powered by
          <a href="https://moovee.io/" target="_blank" @click.stop>moovee</a>
        </span>
      </div>
    </video-background>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/main";
import firebase from "firebase/compat/app";

import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BButton,
  BButtonGroup,
  BListGroupItem,
  BFormSelect,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min, url } from "@validations";
import ru from "vee-validate/dist/locale/ru.json";
import { regex } from "vee-validate/dist/rules";
localize("ru", ru);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BButton,
    BButtonGroup,
    BListGroupItem,
    BFormSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isPaused: false,
      isMuted: true,
      isEditControl: false,
      answersSentSuccess: false,
      isForm: false,
      leadFormsAnswers: {
        leadFormId: null,
        projectId: this.$route.params.id,
        timeAt: firebase.firestore.FieldValue.serverTimestamp(),
        answers: {},
      },
      isMaximized: false,
      maximizedFirst: true,
      isVideoEnded: false,
      isVideoOverlay: false,
      isClosed: false,
      socials: null,
      isSocials: false,
      sendFormColor: "#7367f0",
    };
  },
  computed: {
    ...mapGetters("projects", [
      "getProjectData",
      "getCurrentSource",
      "getControls",
      "getSourcesList",
      "getSocialsList",
    ]),
    ...mapGetters("lead-forms", ["getFormsList", "getCurrentForm"]),
    ...mapGetters("integrations", [
      "getAvailableIntegrations",
      "getProjectIntegrations",
    ]),
    currentVideoControls: {
      get() {
        const controlsClone = [...this.getControls];
        return controlsClone;
      },
      set(newValue) {
        if (newValue)
          this.setControls({ newValue, sourceId: this.getCurrentSource.id });
      },
    },
    currentVideo() {
      if (this.getCurrentSource && this.getCurrentSource.url) {
        const str = this.getCurrentSource.url.replace(
          new RegExp(".mov$"),
          ".mp4"
        );
        return str;
      }
      return null;
    },
  },
  watch: {
    getProjectData(newValue) {
      if (!this.$route.query.is_demo)
        db.collection("shows").add({
          projectId: this.$route.params.id,
          projectName: newValue.name,
          userId: newValue.userId,
          timeAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
  },
  methods: {
    ...mapActions("projects", [
      "requestProjectData",
      "requestNextSource",
      "setControls",
      "addControl",
    ]),
    ...mapActions("lead-forms", [
      "requestForms",
      "requestLeadForm",
      "saveAnswers",
    ]),
    ...mapActions("integrations", [
      "requestAvailableIntegrations",
      "LinkIntegrationToProject",
      "requestProjectIntegrations",
      "triggerIntegrations",
    ]),
    playPauseVideo() {
      if (this.isMaximized) {
        if (this.isPaused) {
          this.$refs.videobackground.player.play();
          this.isVideoOverlay = false;
        } else {
          this.isVideoOverlay = true;
          this.$refs.videobackground.player.pause();
        }
        this.isPaused = !this.isPaused;
      } else {
        if (this.maximizedFirst && !this.$route.query.is_demo) {
          db.collection("projects")
            .doc(this.$route.params.id)
            .update({
              interactions: firebase.firestore.FieldValue.increment(1),
            })
            .then(() => {
              db.collection("interactions").add({
                projectId: this.$route.params.id,
                timeAt: firebase.firestore.FieldValue.serverTimestamp(),
                projectName: this.getProjectData.name,
                userId: this.getProjectData.userId,
              });
            });
        }
        this.maximizedFirst = false;
        window.parent.postMessage({ event_id: "widget_maximize" }, "*");
        this.isMuted = false;
        this.isMaximized = true;
      }
    },
    setVoice() {
      this.isMuted = !this.isMuted;
    },
    handleAction(button) {
      if (!this.$route.query.is_demo)
        db.collection("projects")
          .doc(this.$route.params.id)
          .update({
            clicks: firebase.firestore.FieldValue.increment(1),
          });
      if (button.action == "nextFrame" && button.nextFrame) {
        this.requestNextSource(button.nextFrame);
      } else if (button.action == "url" && button.goTo) {
        window.parent.postMessage(
          {
            event_id: "widget_open_link",
            data: {
              url: button.goTo,
            },
          },
          "*"
        );
      } else if (button.action == "anchor" && button.anchor) {
        window.parent.postMessage(
          {
            event_id: "widget_anchor",
            data: {
              anchor: button.anchor,
            },
          },
          "*"
        );
      } else if (button.action == "leadForm" && button.leadForm) {
        this.displayLeadForm(button);
        this.$refs.videobackground.player.pause();
        this.isVideoOverlay = true;
        this.sendFormColor = button.background
          ? button.background.hex
          : "#7367f0";
      }
      if (button.action == "socials") {
        this.$refs.videobackground.player.pause();
        this.displaySocials(button);
      }
    },
    displayLeadForm(data) {
      this.requestLeadForm(data.leadForm);
      this.leadFormsAnswers.leadFormId = data.leadForm;
      this.isForm = true;
      this.requestProjectIntegrations([...this.getProjectData.integrations]);
    },
    saveFormAnswers() {
      this.$refs.leadFormsRules.validate().then((success) => {
        if (success) {
          this.leadFormsAnswers.userId = this.getCurrentForm.userId;
          this.saveAnswers(this.leadFormsAnswers);
          this.sendIntegrations();
          this.answersSentSuccess = true;
        }
      });
    },
    displaySocials(data) {
      this.socials = data;
      this.isSocials = true;
    },
    openSocial(link) {
      window.open(link, "_blank").focus();
    },
    sendIntegrations() {
      const ans = {};
      for (const [key, value] of Object.entries(
        this.leadFormsAnswers.answers
      )) {
        ans[
          this.findQuestionType(this.leadFormsAnswers.leadFormId, key)
        ] = value;
      }
      const timeAt = new Date().toLocaleString();
      const integrationObj = {
        projectId: this.$route.params.id,
        projectName: this.getProjectData.name,
        sceneId: this.getCurrentSource.id,
        sceneName: this.getCurrentSource.title,
        leadFormId: this.getCurrentForm.displayId,
        leadFormName: this.getCurrentForm.name,
        timeAt,
        ...ans,
      };
      this.triggerIntegrations({
        integrations: this.getProjectIntegrations,
        data: integrationObj,
      });
    },
    findQuestionType(formId, id) {
      let res = {};
      if (this.getCurrentForm)
        res = this.getCurrentForm.questions.find((question) => {
          return question.id === id;
        });
      else return "-";
      if (res) return res.type;
      else return null;
    },
    minimize() {
      this.isMaximized = false;
      this.isMuted = true;
      window.parent.postMessage({ event_id: "widget_minimize" }, "*");
    },
    close() {
      this.$refs.videobackground.player.pause();
      this.isClosed = true;
      window.parent.postMessage({ event_id: "widget_close" }, "*");
    },
    videoEnds() {
      this.isVideoOverlay = true;
      this.isVideoEnded = true;
    },
    videoPlaying() {
      this.isVideoEnded = false;
    },
    videoRepeat() {
      this.isVideoOverlay = false;
      this.$refs.videobackground.player.play();
    },
    closeForm() {
      this.isForm = false;
      this.isVideoOverlay = false;
      this.$refs.videobackground.player.play();
    },
    getValidationType(type) {
      switch (type) {
        case "email":
          return {
            required,
            email,
          };
        case "name":
          return {
            required,
            min: 2,
          };
        case "phone":
          return {
            regex: `([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$`,
            required,
          };
        case "string":
          return {
            required,
          };
      }
      return "";
    },
    mouseover() {
      if (!this.isMaximized && !this.isClosed) {
        window.parent.postMessage({ event_id: "widget_mouse_over" }, "*");
      }
    },
    mouseleave() {
      if (!this.isMaximized && !this.isClosed) {
        window.parent.postMessage({ event_id: "widget_mouse_leave" }, "*");
      }
    },
    connectGoogleAnalytics(tag) {
      let configScript = document.createElement("script");
      configScript.setAttribute(
        "src",
        `https://www.googletagmanager.com/gtag/js?id=${tag}`
      );
      document.head.appendChild(configScript);
      let actionSript = document.createElement("script");
      actionSript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${tag}');
            `;
      document.head.appendChild(actionSript);
      window.dataLayer = window.dataLayer || [];
    },
    connectYandexMetrika(tag) {
      (function(m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function() {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      ym(tag, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      });
    },
    connectFacebookPixel(id) {
      console.log(id);
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", id);
      fbq("track", "PageView");
    },
  },
  mounted() {
    this.requestProjectData(this.$route.params.id).then((ans) => {
      this.requestProjectIntegrations([...ans.integrations]).then(() => {
        if (this.getProjectIntegrations && this.getProjectIntegrations.google) {
          this.getProjectIntegrations.google.forEach((googleTag) => {
            this.connectGoogleAnalytics(googleTag.url);
          });
        }
        if (this.getProjectIntegrations && this.getProjectIntegrations.yandex) {
          this.getProjectIntegrations.yandex.forEach((yandexTag) => {
            this.connectYandexMetrika(yandexTag.url);
          });
        }
        if (
          this.getProjectIntegrations &&
          this.getProjectIntegrations.facebook
        ) {
          this.getProjectIntegrations.facebook.forEach((pixel) => {
            this.connectFacebookPixel(pixel.url);
          });
        }
      });
    });
    window.addEventListener("load", () => {
      window.parent.postMessage({ event_id: "widget_loaded" }, "*");
    });
    if (!this.$route.query.is_demo)
      db.collection("projects")
        .doc(this.$route.params.id)
        .update({
          shows: firebase.firestore.FieldValue.increment(1),
        });
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
html {
  border-radius: 15px;
  .video-wrapper {
    border-radius: 15px;
  }
}

#videoBg {
  .videobg-content {
    display: flex;
    flex-direction: column;
  }
}
.answer-success-overlay {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: -webkit-center;
  text-align: center;
  color: white;
  svg {
    color: #28c76f;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
}
.voice-controller {
  color: white;
}
.video-custom-button {
  height: 100%;
  display: flex;
  .btn-lg {
    height: 52px;
    box-shadow: 0 0 10px rgb(225 255 255 / 50%);
  }
  .custom-button {
    border: transparent;
    border-radius: 8px;
    margin-top: 8px;
    min-height: 52px;
    height: auto;
    span {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      font-size: 1rem;
    }
  }
  .form-elements {
    margin-top: 8px;
  }
  .form-elements-button {
    button {
      border: transparent;
      border-radius: 8px;
    }
    margin-top: 8px;
  }
  > div {
    width: 90%;
    text-align: -webkit-center;
    text-align: -moz-center;
    > div {
      position: absolute;
      bottom: 15px;
      width: inherit;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.socials-wrapper {
  display: flex;
  align-items: center;
  place-content: center;
  > img:first-child {
    margin-left: 8px;
  }
  img {
    margin-right: 8px;
  }
}

.list-group-item {
  transition: all 1s;
}

.quest-footer {
  margin-top: auto;
  height: 40px;
  width: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  place-items: center;
  justify-content: center;
  color: white;
  a {
    color: white;
    font-weight: 700;
  }
}
</style>
